import { Row, Col } from 'antd';
import Cocurricular from '../../components/cocurricular/Cocurricular.component';

import './CocurricularPage.styles.css';

const CocurricularPage = () => (
  <>
    <Row justify="center" id="cocurricular">
      <Col xs={22} s={20} md={18} lg={16} xl={14}>
        <Cocurricular />
      </Col>
    </Row>
  </>
);

export default CocurricularPage;
