import { useState, useEffect } from 'react';
import { Typography, Collapse, Divider } from 'antd';
import { FundTwoTone } from '@ant-design/icons';
import CollapseLeftHeader from '../collapseLeftHeader/CollapseLeftHeader.component';
import TagCollection from '../tagCollection/TagCollection.component';
import { useCollapse } from '../../contexts/Collapse.context';

import './WorkExperiences.styles.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const WorkExperiences = () => {
  const [activeKey, setActiveKey] = useState([]);

  const { isAccordion, isExpandAll } = useCollapse();

  useEffect(() => {
    if (isAccordion === false && isExpandAll) {
      setActiveKey([1, 2, 3]);
    } else {
      setActiveKey([]);
    }
  }, [isAccordion, isExpandAll]);

  return (
    <>
      <Typography>
        <Title>
          <FundTwoTone twoToneColor="#53D018" /> Work
        </Title>
        <Collapse
          accordion={isAccordion}
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key);
          }}
        >
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="Teaching Assistant (CS4218 Module Software Testing)"
                subHeading="National University of Singapore · Part Time"
                period="Jan 2021 to Apr 2021 (4 Months)"
                rightLabelArray={[
                  {
                    name: 'Teaching Assistant',
                    colour: 'purple',
                  },
                  {
                    name: 'CS4218 Software Testing',
                    colour: 'blue',
                  },
                  {
                    name: 'Java',
                    colour: '#1465be',
                  },
                ]}
              />
            )}
            key="1"
          >
            <Paragraph className="panel-description">
              <ul>
                <li>
                  Crafted project specification and base source code for
                  students to build upon and to enable students to apply
                  software testing techniques learnt.
                </li>
                <li>
                  Reviewed and graded students’ projects to ensure well tested
                  with JUnit and provided comments on bugs identified and how
                  teams can improve testing of applications.
                </li>
                <li>
                  Assisted module coordinator in answering student queries with
                  regards to project specifications and project set up to ensure
                  the projects are smoothly conducted.
                </li>
                <li>
                  Improved PMD Static Code Analyser Script to allow easy
                  checking of code styles and potential bugs in students&apos;
                  source code.
                </li>
                <li>
                  Creation of test driven development test cases files for
                  students&apos; usage for next project milestone.
                </li>
              </ul>
            </Paragraph>
            <Divider>Technologies Applied</Divider>
            <TagCollection
              tagArray={[
                'Java',
                'JUnit',
                'Mockito Java Mocking Framework',
                'Continuous Integration',
                'Git',
                'GitHub',
                'PMD Static Code Analyser',
                'Code Coverage',
                'Apache Maven',
              ]}
            />
            <Divider>Skills Applied</Divider>
            <TagCollection
              tagArray={[
                'Software Testing',
                'Technical Documentation',
                'Software Documentation',
                'Project Management',
                'Scrum',
                'Unit Testing',
                'Integration Testing',
                'Acceptance Testing',
                'System Integration Testing',
                'Test Case Design',
                'Software Debugging',
                'Software Quality Assurance',
                'Regression Testing',
                'Test Driven Development',
                'Functional Testing',
              ]}
            />
          </Panel>
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="Project and Test Manager Intern"
                subHeading="Singapore Airlines · Internship"
                period="Jun 2020 to Oct 2020 (5 Months)"
                rightLabelArray={[
                  {
                    name: 'Internship',
                    colour: 'geekblue',
                  },
                  {
                    name: 'Excel Formulas',
                    colour: '#015b27',
                  },
                  {
                    name: 'Visual Basic for Applications (VBA)',
                    colour: '#108ee9',
                  },
                ]}
              />
            )}
            key="2"
          >
            <Paragraph className="panel-description">
              <ul>
                <li>
                  Conducted System Integration Testing (SIT) to identify any
                  potential issues in Finesse solution, used for automated
                  invoice verification, to ensure smooth roll out of Enterprise
                  Planning Tool (EPT) Project, a multi million project which
                  focuses on end to end cost management.
                </li>
                <li>
                  Collaborated with Finesse solution provider, Accelya, and
                  business users to ensure user requirements are met and issues
                  are resolved for quality roll out of the EPT Project.
                </li>
                <li>
                  Identified areas in existing project workflow process which
                  can be improved and leveraged on the capabilities of
                  technologies such as Excel Formulas and Visual Basic for
                  Application (VBA) to automate authorisation matrix generation
                  and analysis of system data to identify outliers. The
                  improvements led to significant reduction in testing time
                  required and project rollout.
                </li>
                <li>
                  Designed User Acceptance Testing (UAT) test scenarios for
                  business users to conduct UAT to verify user requirements are
                  satisfied.
                </li>
                <li>
                  Trained users as part of UAT Training to convey the purpose
                  and background of the project with live system demonstration
                  to showcase users on the various functionalities offered and
                  to test in UAT.
                </li>
              </ul>
            </Paragraph>
          </Panel>
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="Admin Support Assistant"
                subHeading="Singapore Armed Forces (SAF) · National Service"
                period="May 2015 to May 2017 2020 (2 years)"
                rightLabelArray={[
                  {
                    name: 'National Service',
                    colour: 'green',
                  },
                ]}
              />
            )}
            key="3"
          >
            <Paragraph className="panel-description">
              <ul>
                <li>
                  Assigned to do administrative work ranging from customer
                  service via phone calls to face to face communication,
                  ensuring customer enquiries are addressed.
                </li>
                <li>
                  Created step by step documentations and templates for
                  colleagues to guide new colleagues on using inhouse systems
                  and workflows, increasing workplace productivity and ease of
                  training trainees.
                </li>
              </ul>
            </Paragraph>
          </Panel>
        </Collapse>
      </Typography>
    </>
  );
};

export default WorkExperiences;
