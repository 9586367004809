import { Row, Col } from 'antd';
import WorkExperiences from '../../components/workExperiences/WorkExperiences.component';

import './WorkExperiencesPage.styles.css';

const WorkExperiencesPage = () => (
  <>
    <Row justify="center" id="work-experiences">
      <Col xs={22} s={20} md={18} lg={16} xl={14}>
        <WorkExperiences />
      </Col>
    </Row>
  </>
);

export default WorkExperiencesPage;
