import { Typography, Space, Tag } from 'antd';
import PropTypes from 'prop-types';

import './CollapseLeftHeader.styles.css';

const { Title, Text } = Typography;

const CollapseLeftHeader = ({
  mainHeading,
  subHeading,
  period,
  rightLabelArray,
}) => (
  <div className="collapse-header-component">
    <Title level={5} style={{ marginBottom: 0 }}>
      {mainHeading}
    </Title>
    <Text className="collapse-header-text">{subHeading}</Text>
    <Text type="secondary" className="collapse-header-text">
      {period}
    </Text>

    <div className="right-label">
      <Space wrap>
        {rightLabelArray
          ? rightLabelArray.map((label, index) => (
            // As in this case, the array would not dynamically change.
            // eslint-disable-next-line react/no-array-index-key
            <Tag key={index} color={label.colour}>
              {label.name}
            </Tag>
          ))
          : null}
      </Space>
    </div>
  </div>
);

CollapseLeftHeader.defaultProps = {
  rightLabelArray: [],
};

CollapseLeftHeader.propTypes = {
  mainHeading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  period: PropTypes.string.isRequired,
  rightLabelArray: PropTypes.arrayOf(PropTypes.object),
};

export default CollapseLeftHeader;
