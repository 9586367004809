import { Row, Col } from 'antd';
import SideProjects from '../../components/sideProjects/SideProjects.component';

import './SideProjectsPage.styles.css';

const SideProjectsPage = () => (
  <>
    <Row justify="center" id="side-projects">
      <Col xs={22} s={20} md={18} lg={16} xl={14}>
        <SideProjects />
      </Col>
    </Row>
  </>
);

export default SideProjectsPage;
