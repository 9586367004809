import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const CollapseContext = createContext();

const useCollapse = () => useContext(CollapseContext);

const CollapseProvider = ({ children }) => {
  const [isExpandAll, setIsExpandAll] = useState(false);
  const [isAccordion, setIsAccordion] = useState(true);

  const toggleIsExpandAll = () => {
    setIsExpandAll(!isExpandAll);
  };

  const toggleIsAccordion = () => {
    setIsAccordion(!isAccordion);
  };

  const resetIsExpandAll = () => {
    setIsExpandAll(false);
  };

  const value = {
    isExpandAll,
    isAccordion,
    toggleIsExpandAll,
    toggleIsAccordion,
    resetIsExpandAll,
  };

  return (
    <CollapseContext.Provider value={value}>
      {children}
    </CollapseContext.Provider>
  );
};

CollapseProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CollapseProvider, useCollapse };
