import { Layout, Divider } from 'antd';
import NavBar from './components/navBar/NavBar.component';
import AboutPage from './pages/aboutPage/AboutPage.component';
import ProfilePage from './pages/profilePage/ProfilePage.component';
import WorkExperiencesPage from './pages/workExperiencesPage/WorkExperiencesPage.component';
import CourseworkPage from './pages/courseworkPage/CourseworkPage.component';
import SideProjectsPage from './pages/sideProjectsPage/SideProjectsPage.component';
import CocurricularPage from './pages/cocurricularPage/CocurricularPage.component';
import ToolsPage from './pages/toolsPage/ToolsPage.component';

import './App.css';

const { Header, Content, Footer } = Layout;

function App() {
  return (
    <div>
      <Layout>
        <div className="main">
          <Header className="header">
            <NavBar />
          </Header>
          <Content className="content">
            <ProfilePage />
            <Divider className="divider" />
            <AboutPage />
            <Divider className="divider" />
            <ToolsPage />
            <WorkExperiencesPage />
            <Divider className="divider" />
            <CourseworkPage />
            <Divider className="divider" />
            <SideProjectsPage />
            <Divider className="divider" />
            <CocurricularPage />
            <Divider className="divider" />
          </Content>
        </div>
        <Footer className="footer">
          © {new Date().getFullYear()} Lee Wei Hao Jonathan
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
