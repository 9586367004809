import { Component } from 'react';
import { Menu } from 'antd';
import {
  HomeOutlined,
  UserOutlined,
  CheckOutlined,
  BookOutlined,
  CodeOutlined,
  BankOutlined,
  FundOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { NavHashLink } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';

import './NavBar.styles.css';

const { SubMenu } = Menu;

class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      current: 'home',
    };
  }

  handleClick = (e) => {
    this.setState({ current: e.key });
  };

  render() {
    const { current } = this.state;
    return (
      <Menu
        className="navbar"
        onClick={this.handleClick}
        selectedKeys={[current]}
        mode="horizontal"
      >
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <NavHashLink smooth to="#top">
            Home
          </NavHashLink>
        </Menu.Item>
        <Menu.Item key="aboutMe" icon={<UserOutlined />}>
          <NavHashLink smooth to="#about">
            About Me
          </NavHashLink>
        </Menu.Item>
        <SubMenu
          key="experienceSubMenu"
          icon={<CheckOutlined />}
          title="Experiences"
        >
          <Menu.Item key="workExperiences" icon={<FundOutlined />}>
            <NavHashLink smooth to="#work-experiences">
              Work
            </NavHashLink>
          </Menu.Item>
          <Menu.Item key="coursework" icon={<BankOutlined />}>
            <NavHashLink smooth to="#coursework">
              Coursework
            </NavHashLink>
          </Menu.Item>
          <Menu.Item key="sideProjects" icon={<CodeOutlined />}>
            <NavHashLink smooth to="#side-projects">
              Side Projects
            </NavHashLink>
          </Menu.Item>
          <Menu.Item key="cocurricular" icon={<StarOutlined />}>
            <NavHashLink smooth to="#cocurricular">
              Cocurricular
            </NavHashLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key="resume" icon={<BookOutlined />}>
          <NavLink
            to={`${process.env.PUBLIC_URL}/media/documents/LEE_WEI_HAO_JONATHAN_Resume.pdf`}
            target="_blank"
          >
            Resume
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  }
}

export default NavBar;
