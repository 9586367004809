import { useState, useEffect } from 'react';
import { Typography, Collapse } from 'antd';
import { StarTwoTone } from '@ant-design/icons';
import CollapseLeftHeader from '../collapseLeftHeader/CollapseLeftHeader.component';
import { useCollapse } from '../../contexts/Collapse.context';

import './Cocurricular.styles.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const Cocurricular = () => {
  const [activeKey, setActiveKey] = useState([]);

  const { isAccordion, isExpandAll } = useCollapse();

  useEffect(() => {
    if (isAccordion === false && isExpandAll) {
      setActiveKey([1]);
    } else {
      setActiveKey([]);
    }
  }, [isAccordion, isExpandAll]);

  return (
    <>
      <Typography>
        <Title>
          <StarTwoTone twoToneColor="#E5DB18" /> Cocurricular
        </Title>
        <Collapse
          accordion={isAccordion}
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key);
          }}
        >
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="Member"
                subHeading="Service Learning Club in Meridian Junior College"
                period="Jan 2013 to Dec 2014"
                rightLabelArray={[
                  {
                    name: 'Volunteer',
                    colour: 'purple',
                  },
                ]}
              />
            )}
            key="1"
          >
            <Paragraph className="panel-description">
              <ul>
                <li>
                  Coached underprivileged primary school students at Pasir Ris
                  Family Service Centre weekly on subjects ranging from
                  Mathematics to English.
                </li>
              </ul>
            </Paragraph>
          </Panel>
        </Collapse>
      </Typography>
    </>
  );
};

export default Cocurricular;
