import { Avatar, Row, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import Profile from '../../components/profile/Profile.component';

import './ProfilePage.styles.css';

const ProfilePage = () => (
  <>
    <div className="avatar">
      <Avatar
        size={256}
        icon={<UserOutlined />}
        src="https://github.com/jonathanleewh.png"
      />
    </div>
    <Row justify="center">
      <Col xs={22} s={20} md={18} lg={16} xl={14}>
        <Profile />
      </Col>
    </Row>
  </>
);

export default ProfilePage;
