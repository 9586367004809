import { useState, useEffect } from 'react';
import {
  Typography, Collapse, Divider, Tooltip, Button, Space,
} from 'antd';
import { BankTwoTone, GithubOutlined } from '@ant-design/icons';
import CollapseLeftHeader from '../collapseLeftHeader/CollapseLeftHeader.component';
import TagCollection from '../tagCollection/TagCollection.component';
import { useCollapse } from '../../contexts/Collapse.context';

import './Coursework.styles.css';

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const Coursework = () => {
  const [activeKey, setActiveKey] = useState([]);

  const { isAccordion, isExpandAll } = useCollapse();

  useEffect(() => {
    if (isAccordion === false && isExpandAll) {
      setActiveKey([1, 2, 3]);
    } else {
      setActiveKey([]);
    }
  }, [isAccordion, isExpandAll]);

  return (
    <>
      <Typography>
        <Title>
          <BankTwoTone twoToneColor="#9518D0" /> Coursework
        </Title>
        <Collapse
          accordion={isAccordion}
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key);
          }}
        >
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="Project Team Member"
                subHeading="Shell Application Implementation (Java)"
                period="Jan 2020 to Apr 2020"
                rightLabelArray={[
                  {
                    name: 'Application Development',
                    colour: 'purple',
                  },
                  {
                    name: 'CS4218 Software Testing',
                    colour: 'blue',
                  },
                  {
                    name: 'Java',
                    colour: '#1465be',
                  },
                ]}
              />
            )}
            key="1"
          >
            <Paragraph className="panel-description">
              <ul>
                <li>
                  Analysed base source code provided for bugs in existing
                  implementation and investigated the root causes through
                  Software Debugging and Testing. This sped up the
                  implementation of new features as existing bugs are resolved,
                  allowing greater focus on potential bugs introduced during
                  enhancements.
                </li>
                <li>
                  Designed and implemented unit and integration test cases to
                  ensure that implementations are well tested, edge cases are
                  considered while maintaining high code coverage. This is to
                  maximise the software quality.
                </li>
                <li>
                  Conducted Acceptance Testing and documented bugs found in
                  similar applications developed by other teams during
                  module&apos;s hackathon to ascertain software requirements are
                  met.
                </li>
              </ul>
            </Paragraph>
            <Divider>Technologies Applied</Divider>
            <TagCollection
              tagArray={[
                'Java',
                'JUnit',
                'Mockito Java Mocking Framework',
                'Continuous Integration',
                'Git',
                'GitHub',
                'PMD Static Code Analyser',
                'Code Coverage',
                'Apache Maven',
              ]}
            />
            <Divider>Skills Applied</Divider>
            <TagCollection
              tagArray={[
                'Software Testing',
                'Technical Documentation',
                'Software Documentation',
                'Project Management',
                'Scrum',
                'Unit Testing',
                'Integration Testing',
                'Acceptance Testing',
                'System Integration Testing',
                'Test Case Design',
                'Software Debugging',
                'Software Quality Assurance',
                'Regression Testing',
                'Test Driven Development',
                'Functional Testing',
              ]}
            />
            <Divider>Links</Divider>
            <Space
              size="middle"
              wrap
              className="collapse-panel-links-button-collection"
            >
              <Tooltip title="CS4218 GitHub Project Repository (Mirror)">
                <Button
                  shape="round"
                  href="https://github.com/JonathanLeeWH/my-course-module-software-testing-java-project"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  CS4218 GitHub Project Repository
                </Button>
              </Tooltip>
              <Tooltip title="Sample Unit Testing">
                <Button
                  shape="round"
                  href="https://github.com/JonathanLeeWH/cs4218-project-ay1920-s2-2020-team22/blob/master/src/test/java/sg/edu/nus/comp/cs4218/impl/cmd/PipeCommandTest.java"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  Sample Unit Testing
                </Button>
              </Tooltip>
              <Tooltip title="Sample Integration Testing">
                <Button
                  shape="round"
                  href="https://github.com/JonathanLeeWH/cs4218-project-ay1920-s2-2020-team22/blob/master/src/test/java/sg/edu/nus/comp/cs4218/impl/app/RmApplicationIT.java"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  Sample Integration Testing
                </Button>
              </Tooltip>
              <Tooltip title="Sample Software Quality Assurance (QA) Report">
                <Button
                  shape="round"
                  href="https://github.com/JonathanLeeWH/cs4218-project-ay1920-s2-2020-team22/blob/master/Milestone3-QAreport.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  Sample Software QA Report
                </Button>
              </Tooltip>
            </Space>
          </Panel>
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="Project Team Member"
                subHeading="ChairVise 3.0 (Vue.js and Spring Boot)"
                period="Aug 2019 to Nov 2019"
                rightLabelArray={[
                  {
                    name: 'Web Development',
                    colour: 'green',
                  },
                  {
                    name: 'Java',
                    colour: '#1465be',
                  },
                  {
                    name: 'JavaScript',
                    colour: '#BABF08',
                  },
                ]}
              />
            )}
            key="2"
          >
            <Paragraph className="panel-description">
              <ul>
                <li>
                  Constructed send mail notification API endpoint support
                  through utilisation of Java enterprise edition’s JavaMail
                  Library to improve web application usability for users and
                  reusability for future developers.
                </li>
                <li>
                  Enhanced sharing of visualisation and statistics capabilities
                  by adding send generated report in Portable Document Format
                  (PDF) via email functionality, providing more sharing options
                  hence, greater user experience.
                </li>
              </ul>
            </Paragraph>
            <Divider>Technologies Applied</Divider>
            <TagCollection
              tagArray={[
                'Java',
                'JavaScript',
                'Spring Boot',
                'Vue.js',
                'JavaMail Library',
                'Git',
                'GitHub',
              ]}
            />
            <Divider>Skills Applied</Divider>
            <TagCollection
              tagArray={[
                'Design Patterns',
                'Technical Documentation',
                'Software Documentation',
                'UML Diagramming',
                'Project Management',
                'Scrum',
              ]}
            />
            <Divider>Links</Divider>
            <Space
              size="middle"
              wrap
              className="collapse-panel-links-button-collection"
            >
              <Tooltip title="CS3219 GitHub Project Repository (Mirror)">
                <Button
                  shape="round"
                  href="https://github.com/JonathanLeeWH/my-course-module-spring-boot-project"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  CS3219 GitHub Project Repository
                </Button>
              </Tooltip>
            </Space>
          </Panel>
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="Team Lead of MediTabs Project Team"
                subHeading="MediTabs (Java)"
                period="Jan 2019 to Apr 2019"
                rightLabelArray={[
                  {
                    name: 'Application Development',
                    colour: 'purple',
                  },
                  {
                    name: 'CS2103 Software Engineering',
                    colour: 'blue',
                  },
                  {
                    name: 'Java',
                    colour: '#1465be',
                  },
                ]}
              />
            )}
            key="3"
          >
            <Paragraph className="panel-description">
              <ul>
                <li>
                  Led a team of 4 to morph an address book application to an
                  inventory management system which aim to solve real world
                  medicine inventory management problems.
                </li>
                <li>
                  Developed export inventory to comma separated value file
                  support to increase application usability and integration with
                  other inventory management systems.
                </li>
                <li>
                  Built filename validation support to meet Microsoft Windows
                  and unix systems standards, allowing increased portability and
                  reusability.
                </li>
              </ul>
            </Paragraph>
            <Divider>Technologies Applied</Divider>
            <TagCollection
              tagArray={[
                'Java',
                'JUnit',
                'Opencsv Library',
                'AsciiDoc',
                'Git',
                'GitHub',
                'Continuous Integration',
              ]}
            />
            <Divider>Skills Applied</Divider>
            <TagCollection
              tagArray={[
                'Technical Documentation',
                'Software Documentation',
                'UML Diagramming',
                'Team Leadership',
                'Project Management',
                'Scrum',
              ]}
            />
            <Divider>Links</Divider>
            <Space
              size="middle"
              wrap
              className="collapse-panel-links-button-collection"
            >
              <Tooltip title="CS2103 GitHub Project Repository">
                <Button
                  shape="round"
                  href="https://cs2103-ay1819s2-t12-3.github.io/main/"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  CS2103 GitHub Project Repository
                </Button>
              </Tooltip>
              <Tooltip title="My Project Portfolio for CS2103 GitHub Project">
                <Button
                  shape="round"
                  href="https://cs2103-ay1819s2-t12-3.github.io/main/team/jonathanleewh.html"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  Project Portfolio
                </Button>
              </Tooltip>
            </Space>
          </Panel>
        </Collapse>
      </Typography>
    </>
  );
};

export default Coursework;
