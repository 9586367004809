import { useEffect } from 'react';
import {
  Row, Col, Button, Space, Tooltip,
} from 'antd';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { useCollapse } from '../../contexts/Collapse.context';

import './ToolsPage.styles.css';

const ToolsPage = () => {
  const {
    isExpandAll,
    toggleIsExpandAll,
    isAccordion,
    toggleIsAccordion,
    resetIsExpandAll,
  } = useCollapse();

  useEffect(() => {
    if (isAccordion) {
      resetIsExpandAll();
    }
  }, [isAccordion, resetIsExpandAll]);

  return (
    <>
      <Row justify="center">
        <Col xs={22} s={20} md={18} lg={16} xl={14}>
          <div className="accordion-expand-all-buttons">
            <Space>
              <Button onClick={() => toggleIsAccordion()}>
                {isAccordion ? 'Disable Accordion' : 'Enable Accordion'}
              </Button>
              <Tooltip
                title={
                  isAccordion
                    ? 'Accordion have to be disabled to toggle Expand All'
                    : ''
                }
              >
                <Button
                  onClick={() => toggleIsExpandAll()}
                  icon={isExpandAll ? <MinusOutlined /> : <PlusOutlined />}
                  disabled={isAccordion}
                >
                  {isExpandAll ? 'Collapse All' : 'Expand All'}
                </Button>
              </Tooltip>
            </Space>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ToolsPage;
