import { Row, Col } from 'antd';
import About from '../../components/about/About.component';

import './AboutPage.styles.css';

const AboutPage = () => (
  <>
    <Row justify="center" id="about">
      <Col xs={22} s={20} md={18} lg={16} xl={14}>
        <About />
      </Col>
    </Row>
  </>
);

export default AboutPage;
