import { Row, Col } from 'antd';
import Coursework from '../../components/coursework/Coursework.component';

import './CourseworkPage.styles.css';

const CourseworkPage = () => (
  <>
    <Row justify="center" id="coursework">
      <Col xs={22} s={20} md={18} lg={16} xl={14}>
        <Coursework />
      </Col>
    </Row>
  </>
);

export default CourseworkPage;
