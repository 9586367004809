import { useState, useEffect } from 'react';
import {
  Typography, Collapse, Divider, Tooltip, Button, Space,
} from 'antd';
import ReactPlayer from 'react-player/vimeo';
import {
  CodeTwoTone,
  GithubOutlined,
  YoutubeOutlined,
  BugOutlined,
  CloudOutlined,
} from '@ant-design/icons';
import CollapseLeftHeader from '../collapseLeftHeader/CollapseLeftHeader.component';
import TagCollection from '../tagCollection/TagCollection.component';
import { useCollapse } from '../../contexts/Collapse.context';

import './SideProjects.styles.css';

const { Title, Paragraph, Text } = Typography;
const { Panel } = Collapse;

const SideProjects = () => {
  const [activeKey, setActiveKey] = useState([]);

  const { isAccordion, isExpandAll } = useCollapse();

  useEffect(() => {
    if (isAccordion === false && isExpandAll) {
      setActiveKey([1, 2, 3]);
    } else {
      setActiveKey([]);
    }
  }, [isAccordion, isExpandAll]);

  return (
    <>
      <Typography>
        <Title>
          <CodeTwoTone twoToneColor="#ff8da1" /> Side Projects
        </Title>
        <Collapse
          accordion={isAccordion}
          activeKey={activeKey}
          onChange={(key) => {
            setActiveKey(key);
          }}
        >
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="RepoLabels command line interface"
                subHeading="Sync/Import/Export GitHub Labels (Python)"
                period=""
                rightLabelArray={[
                  {
                    name: 'Application Development',
                    colour: 'purple',
                  },
                  {
                    name: 'Simplify Development Workflow',
                    colour: 'cyan',
                  },
                  {
                    name: 'Python',
                    colour: '#bab718',
                  },
                ]}
              />
            )}
            key="1"
          >
            <Paragraph className="panel-description">
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player"
                  url="https://vimeo.com/573268564"
                  height="100%"
                  width="100%"
                  // eslint-disable-next-line react/jsx-boolean-value
                  pip={true}
                />
              </div>
              <ul>
                <li>
                  <Text strong italic>
                    RepoLabels
                  </Text>{' '}
                  is a GitHub Repository Labels command line interface which
                  allows for easy exporting and importing of GitHub Labels.
                </li>
                <li>
                  <Text strong>Purpose:</Text> Given that GitHub does not have
                  an easy solution to synchronise labels across repositories,{' '}
                  <Text strong italic>
                    RepoLabels
                  </Text>{' '}
                  helps to resolve this issue by allowing users to sync, import
                  and export their GitHub Labels.
                </li>
                <li>
                  <Text strong>Benefits:</Text> This avoids users having to
                  manually reconfigure their labels when creating a new GitHub
                  Repository or when planning to synchronise the labels of an
                  existing repository which{' '}
                  <Text underline>helps to save development time.</Text>
                </li>
              </ul>
            </Paragraph>
            <Divider>Technologies Applied</Divider>
            <TagCollection
              tagArray={[
                'Python',
                'Aiohttp',
                'Git',
                'GitHub',
                'GitHub Actions',
              ]}
            />
            <Divider>APIs</Divider>
            <TagCollection tagArray={['GitHub Rest API']} />
            <Divider>Links</Divider>
            <Space
              size="middle"
              wrap
              className="collapse-panel-links-button-collection"
            >
              <Tooltip title="RepoLabels command line interface GitHub Project Repository">
                <Button
                  shape="round"
                  href="https://github.com/lwhjon/repo-labels-cli"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  GitHub Project Repository
                </Button>
              </Tooltip>
              <Tooltip title="RepoLabels command line interface Readme">
                <Button
                  shape="round"
                  href="https://github.com/lwhjon/repo-labels-cli/blob/master/README.md"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  Project Readme
                </Button>
              </Tooltip>
              <Tooltip title="RepoLabels command line interface video demo (YouTube)">
                <Button
                  shape="round"
                  href="https://www.youtube.com/watch?v=30bUD5-spzQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<YoutubeOutlined />}
                >
                  RepoLabels Video demo (YouTube)
                </Button>
              </Tooltip>
              <Tooltip title="RepoLabels command line interface video demo (Vimeo)">
                <Button
                  shape="round"
                  href="https://vimeo.com/573268564"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<YoutubeOutlined />}
                >
                  RepoLabels Video demo (Vimeo)
                </Button>
              </Tooltip>
            </Space>
          </Panel>
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="Software Repository REST API Service (Backend)"
                subHeading="Provides access to various software information such as software description and latest software version (Node.js)"
                period=""
                rightLabelArray={[
                  {
                    name: 'Web Application Development',
                    colour: 'geekblue',
                  },
                  {
                    name: 'Backend',
                    colour: 'blue',
                  },
                  {
                    name: 'CRUD Application',
                    colour: 'geekblue',
                  },
                  {
                    name: 'REST API',
                    colour: 'cyan',
                  },
                  {
                    name: 'Docker',
                    colour: '#108ee9',
                  },
                  {
                    name: 'Firebase Authentication',
                    colour: 'gold',
                  },
                  {
                    name: 'Node.js',
                    colour: '#87d068',
                  },
                ]}
              />
            )}
            key="2"
          >
            <Paragraph className="panel-description">
              <ul>
                <li>
                  <Text strong italic>
                    Software Repository REST API Service
                  </Text>{' '}
                  provides access to various software information such as
                  software description and latest software version.
                </li>
                <li>
                  <Text strong>Purpose:</Text> There is a lack of a REST API for
                  softwares and this project aims to address this problem by
                  providing a REST API for users to query/submit software
                  information.
                </li>
                <li>
                  <Text strong>Aim:</Text>{' '}
                  <Text strong italic>
                    Software Repository
                  </Text>{' '}
                  aims to be a platform to share and discover softwares. The
                  platform also allows software developers to add their
                  softwares to the platform and share them with the rest of the
                  world.
                </li>
              </ul>
            </Paragraph>
            <Divider>Technologies Applied</Divider>
            <TagCollection
              tagArray={[
                'Node.js',
                'Express.js',
                'Docker',
                'MongoDB',
                'Firebase Authentication',
                'Jest',
                'ESLint',
                'Git',
                'GitHub',
                'GitHub Actions',
              ]}
            />
            <Divider>Backend Technologies</Divider>
            <TagCollection
              tagArray={[
                'Node.js',
                'Express.js',
                'MongoDB',
                'Firebase Authentication',
                'Docker',
              ]}
            />
            <Divider>Links</Divider>
            <Space
              size="middle"
              wrap
              className="collapse-panel-links-button-collection"
            >
              <Tooltip title="Software Repository REST API Service (Backend) GitHub Project Repository">
                <Button
                  shape="round"
                  href="https://github.com/learnsoftwaredevelopment/SoftwareRepository"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  GitHub Project Repository
                </Button>
              </Tooltip>
              <Tooltip title="Software Repository REST API Service (Backend) Readme">
                <Button
                  shape="round"
                  href="https://github.com/learnsoftwaredevelopment/SoftwareRepository/blob/master/README.md"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  Project Readme
                </Button>
              </Tooltip>
              <Tooltip
                title="Software Repository REST API Service (Backend) demonstration Hosted on Microsoft Azure [It is
                  currently not available as free quota exceeded]"
              >
                <Button
                  shape="round"
                  href="https://softwarerepository.azurewebsites.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<CloudOutlined />}
                  disabled
                >
                  Backend API Server (demo) Hosted on Microsoft Azure
                </Button>
              </Tooltip>
              <Tooltip title="Software Repository REST API Service (Backend) demonstration Hosted on Heroku">
                <Button
                  shape="round"
                  href="https://softwarerepository.herokuapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<CloudOutlined />}
                >
                  Backend API Server (demo) Hosted on Heroku
                </Button>
              </Tooltip>
              <Tooltip title="Software Repository (Frontend And Backend) demonstration">
                <Button
                  shape="round"
                  href="https://softwarerepository.lwhjon.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<CloudOutlined />}
                >
                  Frontend And Backend (demo)
                </Button>
              </Tooltip>
              <Tooltip title="Sample API endpoint Testing">
                <Button
                  shape="round"
                  href="https://github.com/learnsoftwaredevelopment/SoftwareRepository/blob/master/tests/api/users.test.js"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<BugOutlined />}
                >
                  Sample API endpoint Testing
                </Button>
              </Tooltip>
            </Space>
          </Panel>
          <Panel
            header={(
              <CollapseLeftHeader
                mainHeading="Software Repository (Frontend)"
                subHeading="React Web Application Frontend for Software Repository REST API Service (React)"
                period=""
                rightLabelArray={[
                  {
                    name: 'Web Development',
                    colour: 'green',
                  },
                  {
                    name: 'Frontend',
                    colour: 'purple',
                  },
                  {
                    name: 'CRUD Application',
                    colour: 'geekblue',
                  },
                  {
                    name: 'React',
                    colour: 'blue',
                  },
                  {
                    name: 'REST API',
                    colour: 'cyan',
                  },
                  {
                    name: 'Firebase Authentication',
                    colour: 'gold',
                  },
                  {
                    name: 'Javascript',
                    colour: '#BABF08',
                  },
                ]}
              />
            )}
            key="3"
          >
            <Paragraph className="panel-description">
              <ul>
                <li>
                  <Text strong italic>
                    Software Repository
                  </Text>{' '}
                  is a React Web Application Frontend for Software Repository
                  REST API Service.
                </li>
                <li>
                  <Text strong>Aim:</Text>{' '}
                  <Text strong italic>
                    Software Repository
                  </Text>{' '}
                  aims to be a platform to share and discover softwares. The
                  platform also allows software developers to add their
                  softwares to the platform and share them with the rest of the
                  world.
                </li>
              </ul>
            </Paragraph>
            <Divider>Technologies Applied</Divider>
            <TagCollection
              tagArray={[
                'React',
                'React Router',
                'Bootstrap',
                'React Bootstrap',
                'Firebase Authentication',
                'ESLint',
                'Git',
                'GitHub',
                'GitHub Actions',
              ]}
            />
            <Divider>Frontend Technologies</Divider>
            <TagCollection
              tagArray={[
                'React',
                'Bootstrap',
                'React Bootstrap',
                'Firebase Authentication',
                'React Router',
              ]}
            />
            <Divider>Links</Divider>
            <Space
              size="middle"
              wrap
              className="collapse-panel-links-button-collection"
            >
              <Tooltip title="Software Repository (Frontend) GitHub Project Repository">
                <Button
                  shape="round"
                  href="https://github.com/learnsoftwaredevelopment/software-repository-web-app"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<GithubOutlined />}
                >
                  GitHub Project Repository
                </Button>
              </Tooltip>
              <Tooltip
                title="Software Repository REST API Service (Backend) demonstration Hosted on Microsoft Azure [It is
                  currently not available as free quota exceeded]"
              >
                <Button
                  shape="round"
                  href="https://softwarerepository.azurewebsites.net"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<CloudOutlined />}
                  disabled
                >
                  Backend API Server (demo) Hosted on Microsoft Azure
                </Button>
              </Tooltip>
              <Tooltip title="Software Repository REST API Service (Backend) demonstration Hosted on Heroku">
                <Button
                  shape="round"
                  href="https://softwarerepository.herokuapp.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<CloudOutlined />}
                >
                  Backend API Server (demo) Hosted on Heroku
                </Button>
              </Tooltip>
              <Tooltip title="Software Repository (Frontend And Backend) demonstration">
                <Button
                  shape="round"
                  href="https://softwarerepository.lwhjon.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  icon={<CloudOutlined />}
                >
                  Frontend And Backend (demo)
                </Button>
              </Tooltip>
            </Space>
          </Panel>
        </Collapse>
      </Typography>
    </>
  );
};

export default SideProjects;
