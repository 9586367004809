import { Typography } from 'antd';
import { IdcardTwoTone } from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

const About = () => (
  <>
    <Typography>
      <Title>
        <IdcardTwoTone /> About Me
      </Title>
      <Paragraph>
        I am a Year 4 Computer Science Student at National University of
        Singapore. My focus area is <Text strong>Software Engineering</Text>.
      </Paragraph>
      <Paragraph>
        Currently, the programming language which I often use is
        <Text strong> JavaScript</Text>. I also have experience coding in
        programming languages such as
        <Text strong> Java, Python, SQL, C, C++</Text>.
      </Paragraph>
      <Paragraph>
        My hobbies include learning/improving my skills through online courses
        and reading Chinese Novels.
      </Paragraph>
    </Typography>
  </>
);

export default About;
