import {
  Typography, Tooltip, Button, Space,
} from 'antd';
import {
  GithubOutlined,
  LinkedinOutlined,
  MailOutlined,
  BookOutlined,
} from '@ant-design/icons';

import './Profile.styles.css';

const { Title, Paragraph } = Typography;

const Profile = () => (
  <>
    <Typography className="avatar-description">
      <Title level={2}>Lee Wei Hao Jonathan</Title>
      <Paragraph>
        Year 4 Computer Science student at National University of Singapore
      </Paragraph>
    </Typography>
    <div className="social">
      <Space size="middle">
        <Tooltip title="GitHub">
          <Button
            size="large"
            shape="circle"
            href="https://github.com/jonathanleewh"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GithubOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Linkedin">
          <Button
            size="large"
            shape="circle"
            href="https://www.linkedin.com/in/jonathanleewh/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedinOutlined />
          </Button>
        </Tooltip>
        <Tooltip title="Mail">
          <Button
            size="large"
            shape="circle"
            href="mailto:leeweihao1996@outlook.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MailOutlined />
          </Button>
        </Tooltip>
      </Space>
    </div>
    <div className="resume-download">
      <Tooltip title="My Resume">
        <Button
          type="primary"
          icon={<BookOutlined />}
          size="large"
          shape="round"
          href={`${process.env.PUBLIC_URL}/media/documents/LEE_WEI_HAO_JONATHAN_Resume.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Resume
        </Button>
      </Tooltip>
    </div>
  </>
);

export default Profile;
