import { Tag, Space } from 'antd';
import PropTypes from 'prop-types';

const TagCollection = ({ tagArray }) => (
  <>
    <Space wrap>
      {tagArray.slice().sort().map((tag, i) => (
        // As in this case, the array would not dynamically change.
        // eslint-disable-next-line react/no-array-index-key
        <Tag key={i}>{tag}</Tag>
      ))}
    </Space>
  </>
);

TagCollection.propTypes = {
  tagArray: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TagCollection;
